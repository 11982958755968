import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import HeroBanner from 'components/sections/HeroBanner';
import FaqInfo from 'components/sections/FaqInfo';
import OrderedList from '../../components/sections/OrderedList';
import { Container } from '../../layout';

import { IPropsFaqPage } from './models';

import './FaqPage.scss';

const FaqPage: FC<IPropsFaqPage> = ({
  data: {
    pageData,
  },
  pageContext,
}) => {
  const {
    seo,
    urls,
    createDate,
    updateDate,
    heroBanner,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
    faqInfo,
    references,
  } = pageData;
  const { breadcrumbs, lang, url } = pageContext;

  return (
    <Layout
      data-testid="FaqPage"
      seo={seo}
      lang={lang}
      urls={urls}
      faqInfo={faqInfo}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="Faq-Page"
    >
      <HeroBanner
        data={heroBanner}
        breadcrumbs={breadcrumbs}
        isShowBreadcrumbsMobile={showBreadcrumbsMobile}
        isShowBreadcrumbsDesktop={showBreadcrumbsDesktop}
      />
      {faqInfo?.length ? <FaqInfo data={faqInfo} /> : null}
      {references?.length ? (
        <Container>
          <OrderedList data={references} />
        </Container>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query (
    $url: String!
    $lang: String
  ) {
    pageData: faqPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentFaqPage
    }
    siteSettings: siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
  }
`;

export default FaqPage;
