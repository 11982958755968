import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { Platform } from '@phx-husky/fetch-lds';
import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';
import useAddClassToHtml from 'hooks/useAddClassToHtml';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';
import LdsBody from 'components/lds/LdsBody';
import HeroBanner from 'components/sections/HeroBanner';
import { SMOOTH_SCROLL_CLASSNAME } from 'utils/constants';

import { IPropsLdsPage } from './models';

import './LdsPage.scss';

const LdsPage: FC<IPropsLdsPage> = ({ data: { pageData }, pageContext }) => {
  const {
    seo,
    urls,
    ldsSettings,
    createDate,
    updateDate,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
    heroBanner,
    ldsManualBody,
    ldsManualOrSystem,
    updateDateText,
  } = pageData;
  const { breadcrumbs, lang, url } = pageContext;
  useAddClassToHtml({ className: SMOOTH_SCROLL_CLASSNAME });

  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_OT_LDS_BASE_URL as string,
    businessId: ldsSettings?.[0]?.businessId ?? '',
    versionId: ldsSettings?.[0]?.versionId ?? '',
    platform: ldsSettings?.[0]?.platform ?? '',
  });

  const shouldDisplayLdsContainer = useMemo(
    () => ldsSettings?.[0]?.platform === Platform.OneTrust || ldsManualOrSystem,
    []
  );

  const bodyContent = updatedLdsContent ?? ldsSettings?.[0]?.body;

  return (
    <Layout
      data-testid="LdsPage"
      seo={seo}
      lang={lang}
      urls={urls}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="lds-page"
    >
      <HeroBanner
        data={heroBanner}
        breadcrumbs={breadcrumbs}
        isShowBreadcrumbsMobile={showBreadcrumbsMobile}
        isShowBreadcrumbsDesktop={showBreadcrumbsDesktop}
      />
      {!isCheckingLdsVersion && shouldDisplayLdsContainer && bodyContent ? (
        <Container className="lds-container">
          <DangerouslySetInnerHtml className="lds-wrapper" html={bodyContent} />
        </Container>
      ) : null}
      {!shouldDisplayLdsContainer ? (
        <LdsBody
          updateDateText={updateDateText}
          ldsManualBody={ldsManualBody}
          lang={lang}
          updateDate={updateDate}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String!) {
    pageData: ldsPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentLdsPage
    }
  }
`;

export default LdsPage;
